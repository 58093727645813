
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { required, email } from 'vuelidate/lib/validators'
import CompletionBuy from '~/components/buy/CompletionBuy.vue'

@Component({
  // @ts-ignore
  amp: 'hybrid',
  ampLayout: 'amp',

  components: {
    CompletionBuy
  }
})
export default class extends Vue {
  @Prop() paintings

  isShow: boolean = true
  isShowMakingPurchase: boolean = true
  isErrorSend: boolean = false
  formData: object = {}

  data () {
    return {
      formData: {
        id: this.paintings.id,
        name: '',
        phone: '',
        email: '',
        address: ''
      }
    }
  }

  head () {
    return {
      title: this.$t('head.title.buy'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('head.description.buy') },
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.buy') },
        { hid: 'og:description', name: 'og:description', content: this.$t('head.description.buy') },
        { hid: 'og:image', name: 'og:image', content: require('~/assets/img/logo-black.svg') },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  }

  onSubmit () {
    this.$v.$touch()
    if (this.$v.$error) {
      return
    }

    this.isErrorSend = false

    this.$axios.post(
      process.env.API_ENDPOINT + '/api/v3/paintings/send-buy-request', this.formData
    ).then(() => {
      this.isShow = false
    }).catch(() => {
      this.isErrorSend = true
    })
  }

  backMakingPurchase () {
    this.isShowMakingPurchase = true
    this.$eventBus.$emit('isShowMakingPurchase', this.isShowMakingPurchase)
  }

  validations () {
    return {
      formData: {
        name: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required
        },
        address: {
          required
        }
      }
    }
  }
}
