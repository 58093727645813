
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
  // @ts-ignore
  amp: 'hybrid',
  ampLayout: 'amp'
})
export default class extends Vue {
  @Prop() paintings

  isShow: boolean = true
  painting = []

  mounted () {
    this.$axios.get(
      process.env.API_ENDPOINT + '/api/v3/artists/' + this.paintings.artist_id,
      {
        // @ts-ignore
        requireToken: false,
        // @ts-ignore
        cache: true
      }
    ).then((response) => {
      this.painting = response.data.paintings.slice(0, 3)
    })
  }
}
