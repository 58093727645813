
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import MakingPurchase from '~/components/buy/MakingPurchase.vue'

@Component({
  // @ts-ignore
  amp: 'hybrid',
  ampLayout: 'amp',

  components: {
    MakingPurchase
  }
})
export default class extends Vue {
  @Prop() painting
  @Prop() isShowBuy

  isShow: boolean = true
  paintId
  paintings: {
    sale_height_cm: 0,
    sale_width_cm: 0
  }

  sizePainting: {
    width: number,
    height: number
  }

  isShowMakingPurchase : boolean = true
  isShowSwiper: boolean = true

  data () {
    return {
      paintings: {},
      sizePainting: {
        width: 0,
        height: 0
      }
    }
  }

  head () {
    return {
      title: this.$t('head.title.buy'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('head.description.buy') },
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.buy') },
        { hid: 'og:description', name: 'og:description', content: this.$t('head.description.buy') },
        { hid: 'og:image', name: 'og:image', content: require('~/assets/img/logo-black.svg') },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  }

  mounted () {
    this.$eventBus.$on('isShowMakingPurchase', (isShow) => {
      this.isShowMakingPurchase = isShow
    })

    this.$axios.get(
      process.env.API_ENDPOINT + '/api/v3/paintings/' + this.painting.id + '/get-sale-info',
      {
        // @ts-ignore
        requireToken: true
      }
    ).then((response) => {
      this.paintings = response.data
      this.getWidthPainting()
      this.getHeightPainting()
      return this.paintings
    }).catch(() => {
      this.cancelBuy()
    })
  }

  getWidthPainting () {
    this.sizePainting.width = 120 / (200 / this.paintings.sale_width_cm)
  }

  getHeightPainting () {
    this.sizePainting.height = 120 / (200 / this.paintings.sale_height_cm)
  }

  cancelBuy () {
    this.isShowSwiper = false
    this.$eventBus.$emit('isShowSwiper', this.isShowSwiper)
    this.$emit('close')
  }

  showBlock () {
    this.isShow = !this.isShow
  }

  showMakingPurchase () {
    this.isShowMakingPurchase = false
  }
}
